<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="80px"
          class="topS"
          ref="seekData"
          :model="seekData"
        >
          <el-row :gutter="10">
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  @change="selectBrand"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in hotelSelectData"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="状态">
                <el-select
                  v-model="seekData.sta"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option value="1" label="插卡中">插卡中</el-option>
                  <el-option value="2" label="已取卡">已取卡</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="房间号" prop="pmsRoomNumber">
                <el-input v-model="seekData.roomNo" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="插卡时间" prop="time">
                <el-date-picker
                  v-model="seekData.time"
                  type="datetimerange"
                  :default-time="defaultTime"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 150%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="tab"
          >
            <el-table-column prop="hid" label="酒店ID" width="150"></el-table-column>
            <el-table-column prop="hname" label="酒店名称" width="300"></el-table-column>
            <el-table-column prop="roomNo" label="房间号" width="150"></el-table-column>
            <el-table-column prop="mid" label="主机ID" width="300"></el-table-column>
            <el-table-column prop="sta" label="状态" width="150">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.sta === 1">取电中</el-tag>
                <el-tag v-if="scope.row.sta === 2" type="success">已取卡</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="ctype" label="房卡类型" width="150">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.ctype === 'guest'" type="success">客人卡</el-tag>
                <el-tag v-if="scope.row.ctype === 'service'" type="warning">服务员卡</el-tag>
                <el-tag v-if="scope.row.ctype === 'manage'" type="danger">管理卡</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="ffSta" label="标记">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.ffSta === true" type="danger">疑似飞房</el-tag>
                <el-tag v-if="scope.row.ffSta === false" type="success">无</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="it" label="插卡时间"></el-table-column>
            <el-table-column prop="ot" label="取卡时间"></el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <el-drawer title="原始数据" :visible.sync="drawer">
      <div class="right-box">
        <el-timeline>
          <el-timeline-item
            v-for="(data, index) in originData"
            :key="index"
            :timestamp="data.ct"
            placement="top"
          >
            <el-card>
              <pre>{{ JSON.stringify(data.pmsData, null, 4) }}</pre>
            </el-card>
          </el-timeline-item>
        </el-timeline>

        <el-pagination
          background
          layout="prev, pager, next, sizes, total, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="originSeekData.pageSize"
          :current-page="originSeekData.pageNum"
          :total="originDataTotal"
          @current-change="handleCurrentOrginDataChange"
          @size-change="handleSizeOrginDataChange"
        ></el-pagination>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getElectricDataList,
  getHotelSelect,
  getBrandSelect,
} from "@/api";

export default {
  components: {},
  data() {
    return {
      height: 0,
      selectList: [],
      loading: false,
      // 品牌列表数据
      tableData: [],
      // 查询的数据
      total: null,
      seekData: {
        hotelId: null,
        roomNo: null,
        sta: null,
        mid: null,
        time: null,
        pageSize: 10,
        pageNum: 1,
      },
      query: {},
      brandSelectData: null,
      hotelSelectData: null,
      originData: null,
      originDataTotal: null,
      drawer: false,
      reverse: true,
      originSeekData: {
        pmsHotelId: null,
        pmsRoomNumber: null,
        pageSize: 10,
        pageNum: 1,
      },
      defaultTime: ["00:00:00", "23:59:59"],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {},
  created() {
    // 调用初始化表格数据方法
    this.initialize();
    //this.getList();
  },
  methods: {
    initialize() {
      getBrandSelect().then((res) => {
        if (res.data.data) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    selectBrand(id) {
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.seekData.hotelId = null;
          this.hotelSelectData = res.data.data;
        }
      });
    },
    tab(list) {
      this.selectList = list;
    },

    // 初始化表格数据
    getList() {
      this.loading = true;
      getElectricDataList(this.seekData)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    bindViewClose() {
      this.getList();
    },
    // 查询按钮
    handleSearch() {
      this.seekData.pageNum = 1;
      this.query = Object.assign({}, this.seekData);
      this.getList();
    },
    // 重置按钮
    reset() {
      this.seekData.pageNum = 1;
      this.$refs.seekData.resetFields();
      this.handleSearch();
    },
    // 列表分页
    handleCurrentChange(pageNum) {
      this.seekData.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.getList();
    },
    // 原始数据分页
    handleCurrentOrginDataChange(pageNum) {
      this.originSeekData.pageNum = pageNum;
      this.getOriginList();
    },
    handleSizeOrginDataChange(psize) {
      this.originSeekData.pageSize = psize;
      this.originSeekData.pageNum = 1;
      this.getOriginList();
    },
  },
};
</script>
<style lang="scss" scoped>
.right-box {
  padding-left: 20px;
}
</style>
